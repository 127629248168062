import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
    const [nav, setNav] = useState(false);
    const [navbarBg, setNavbarBg] = useState('bg-gray-900');

    const handleNav = () => {
        setNav(!nav);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarBg('bg-gray-800 shadow-md');
            } else {
                setNavbarBg('bg-gray-900');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const topPage = () => {
        window.scrollTo(0, 0);
    };

    return (
        <nav className={`w-full sticky top-0 z-30 py-6 md:py-7 border-b pb-3 border-white ${navbarBg}`}>
            <div className="max-w-full mx-auto font-[poppins]">
                <div className="flex justify-between items-center px-4 sm:px-9 w-full xl:w-[90%] mx-auto">

                    <div className="flex items-center">
                        <Link to="/" onClick={topPage}>
                            <h3 className="flex font-bold uppercase text-[20px] md:text-[28px] text-white">Murugavel</h3>
                        </Link>
                    </div>

                    <ul className="hidden lg:flex items-center w-fit justify-center font-bold gap-x-4 xl:gap-x-8">
                        <li className="cursor-pointer duration-300 text-white text-[12px] xl:text-[14px]">
                            <ScrollLink to="about" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="tracking-[.02em] hover:text-black/65 font-bold focus:text-red-600">
                                        About Us
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>

                        <li className="cursor-pointer duration-300 text-white text-[12px] xl:text-[14px]">
                            <ScrollLink to="services" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="tracking-[.02em] hover:text-black/65 font-bold focus:text-red-600">
                                        Services
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>
                        <li className="cursor-pointer duration-300 text-white text-[12px] xl:text-[14px]">
                            <ScrollLink to="works" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="tracking-[.02em] hover:text-black/65 font-bold focus:text-red-600">
                                        Works
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>

                        <li className="cursor-pointer duration-300 text-white text-[12px] xl:text-[14px]">
                            <ScrollLink to="resume" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="tracking-[.02em] hover:text-black/65 font-bold focus:text-red-600">
                                        Resume
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>

                        <li className="cursor-pointer duration-300 text-white text-[12px] xl:text-[14px]">
                            <ScrollLink to="skills" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="tracking-[.02em] hover:text-black/65 font-bold focus:text-red-600">
                                        Skills
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>
                        <li className="cursor-pointer duration-300 text-white text-[12px] xl:text-[14px]">
                            <ScrollLink to="contact" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="tracking-[.02em] hover:text-black/65 font-bold focus:text-red-600">
                                        Contact
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>
                    </ul>

                    <div className="flex items-center">
                        <a href="https://api.whatsapp.com/send?phone=6379172058" target="_blank" rel="noopener noreferrer" className="">
                            <button className="px-4 py-1 text-[12px] md:text-[14px] duration-300 shadow-sm drop-shadow-md shadow-gray-100 hover:shadow-green-200 hover:shadow-sm hover:drop-shadow-md hover:scale-105 bg-gray-900 text-white rounded-full">
                                Hire me!
                            </button>
                        </a>

                        <div onClick={handleNav} className="block lg:hidden ml-4">
                            {nav ? <AiOutlineClose className="w-5 h-5 text-gray-50" /> : <AiOutlineMenu className="w-5 h-5 text-gray-50" />}
                        </div>
                    </div>


                    <ul className={`fixed lg:hidden left-0 top-0 w-[60%] h-[100%] border-r bg-gray-50 ease-in-out duration-500 ${nav ? 'block' : 'hidden'}`}>
                        <Link to="/" onClick={topPage} className="w-full">
                            <h3 className="w-36 sm:w-40 pt-10 ml-10 font-bold uppercase text-[24px]" onClick={handleNav}>Murugavel</h3>
                        </Link>

                        <li className="ml-10 cursor-pointer duration-300 text-gray-700 text-[13px] mt-8">
                            <ScrollLink to="about" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="font-bold tracking-[.02em] hover:text-white/50 focus:text-red-600" onClick={handleNav}>
                                        About Us
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>
                        <li className="ml-10 cursor-pointer duration-300 text-gray-700 text-[13px] mt-8">
                            <ScrollLink to="services" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="font-bold tracking-[.02em] hover:text-white/50 focus:text-red-600" onClick={handleNav}>
                                        Services
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>
                        <li className="ml-10 cursor-pointer duration-300 text-gray-700 text-[13px] mt-8">
                            <ScrollLink to="works" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="font-bold tracking-[.02em] hover:text-white/50 focus:text-red-600" onClick={handleNav}>
                                        Works
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>
                        <li className="ml-10 cursor-pointer duration-300 text-gray-700 text-[13px] mt-8">
                            <ScrollLink to="resume" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="font-bold tracking-[.02em] hover:text-white/50 focus:text-red-600" onClick={handleNav}>
                                        Resume
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>
                        <li className="ml-10 cursor-pointer duration-300 text-gray-700 text-[13px] mt-8">
                            <ScrollLink to="skills" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="font-bold tracking-[.02em] hover:text-white/50 focus:text-red-600" onClick={handleNav}>
                                        Skills
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>
                        <li className="ml-10 cursor-pointer duration-300 text-gray-700 text-[13px] mt-8">
                            <ScrollLink to="contact" smooth={true} duration={500} onClick={topPage}>
                                <p className="relative group flex items-center">
                                    <button className="font-bold tracking-[.02em] hover:text-white/50 focus:text-red-600" onClick={handleNav}>
                                        Contact
                                    </button>
                                </p>
                            </ScrollLink>
                        </li>
                    </ul>


                </div>
            </div>
        </nav>
    );
};

export default Header;
