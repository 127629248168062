import React from 'react'

import { FaGithub } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { MdDesignServices } from "react-icons/md";
import { AiOutlineLinkedin } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";

import resume from '../images/Murugavel_Resume_web_dev.pdf';
import { Link } from 'react-router-dom';




const Footer = () => {

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = resume;
        link.download = 'Murugavel_Resume.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <>
            <div className='w-full bg-gray-950 py-4 md:py-6 px-6'>
                <div className='flex flex-row gap-y-6 md:gap-y-8 justify-between md:justify-around items-center  gap-x-4 md:gap-x-6 mt-2 md:mt-5'>
                    <div>
                        <button
                            onClick={handleDownload}
                            className='text-[12px] md:text-[14px] rounded-md md:rounded-xl border border-gray-100 text-white px-4 py-2 '
                        >
                            Download Resume
                        </button>
                    </div>
                    <div className='flex items-center gap-x-8'>
                        <Link to='https://github.com/murugaveldev'>
                            <div className='bg-white cursor-pointer size-7 md:size-9 rounded-full flex justify-center items-center drop-shadow-sm border border-gray-400 shadow-md shadow-gray-100'>
                                <FaGithub className='text-[13px] md:text-[18px] text-gray-900   hover:text-yellow-400' />
                            </div>
                        </Link>
                        <Link to='https://www.linkedin.com/in/murugavel-subramaniyan-42407a20a/'>
                            <div className='bg-white cursor-pointer size-7 md:size-9 rounded-full flex justify-center items-center drop-shadow-sm border border-gray-400 shadow-md shadow-gray-100'>
                                <AiOutlineLinkedin className='text-[13px] md:text-[18px] text-gray-900   hover:text-yellow-400' />
                            </div>
                        </Link>
                        <Link to=''>
                            <div className='bg-white cursor-pointer size-7 md:size-9 rounded-full flex justify-center items-center drop-shadow-sm border border-gray-400 shadow-md shadow-gray-100'>
                                <FiTwitter className='text-[13px] md:text-[18px] text-gray-900   hover:text-yellow-400' />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer