import React from 'react';

// icons
import { FaGithub } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";
import { MdDesignServices } from "react-icons/md";
import { RiWhatsappLine } from "react-icons/ri";



import { IoLogoHtml5 } from "react-icons/io5";
import { IoLogoCss3 } from "react-icons/io";
import { TbBrandJavascript } from "react-icons/tb";
import { FaNodeJs } from "react-icons/fa";
import { SiMongodb } from "react-icons/si";
import { GrMysql } from "react-icons/gr";
import { GrReactjs } from "react-icons/gr";
import { SiTailwindcss } from "react-icons/si";
import { SiFigma } from "react-icons/si";
import { FaGitAlt } from "react-icons/fa";
import { CiServer } from "react-icons/ci";

import { AiOutlineLinkedin } from "react-icons/ai";
import { FiTwitter } from "react-icons/fi";




// images and files
import mine from '../images/mine.png';
import resume from '../images/Murugavel_Resume_web_dev.pdf';
import Tabs from '../components/Tabs';
import Contact from '../components/Contact';
import contact from '../images/new.png'
import { Link } from 'react-router-dom';

const Home = () => {

    // Function to handle the resume download
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = resume;
        link.download = 'Murugavel_Resume.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className='overflow-hidden bg-gradient-to-tl from-purple-500  via-violet-300  to-violet-800' >
                <section className='pt-4 md:pt-20 pb-6 md:pb-20'>
                    <div className='w-full'>
                        <div className='flex flex-col md:flex-row items-center justify-between font-[poppins] w-full px-4 md:w-[80%] gap-y-16 mx-auto mt-12'>

                            <div className='w-full md:w-[50%] mx-auto'>
                                <div>
                                    <div>
                                        <h3 className='font-semibold text-white text-[20px] md:text-[38px]'>I Am Murugavel</h3>
                                        <h1 className='font-bold text-[34px] md:text-[65px] text-yellow-400 drop-shadow-lg  uppercase'>Web Developer +</h1>
                                        <h1 className='font-bold text-[34px] md:text-[65px] text-yellow-400 drop-shadow-lg  uppercase'>UI Designer</h1>
                                        <div className='my-2'>
                                            <p className='text-[12px] md:text-[14px] font-[roboto] w-full md:w-[80%] text-gray-200'>
                                                I break down complex user experience problems to create integrity-focused solutions that connect billions of people.
                                            </p>
                                        </div>
                                        <div className='flex items-center  gap-x-4 md:gap-x-6 mt-8'>
                                            <div>
                                                <button
                                                    onClick={handleDownload}
                                                    className='text-[12px] md:text-[14px] rounded-md md:rounded-xl border border-gray-100 text-white px-4 py-2 '
                                                >
                                                    Download Resume
                                                </button>
                                            </div>
                                            <div className='flex items-center gap-x-8'>
                                                <Link to='https://github.com/murugaveldev'>
                                                    <div className='bg-white cursor-pointer size-7 md:size-9 rounded-full flex justify-center items-center drop-shadow-sm border border-gray-400 shadow-md shadow-gray-100'>
                                                        <FaGithub className='text-[13px] md:text-[18px] text-gray-900   hover:text-yellow-400' />
                                                    </div>
                                                </Link>
                                                <Link to='https://www.linkedin.com/in/murugavel-subramaniyan-42407a20a/'>
                                                    <div className='bg-white cursor-pointer size-7 md:size-9 rounded-full flex justify-center items-center drop-shadow-sm border border-gray-400 shadow-md shadow-gray-100'>
                                                        <AiOutlineLinkedin className='text-[13px] md:text-[18px] text-gray-900   hover:text-yellow-400' />
                                                    </div>
                                                </Link>
                                                <div className='bg-white cursor-pointer size-7 md:size-9 rounded-full flex justify-center items-center drop-shadow-sm border border-gray-400 shadow-md shadow-gray-100'>
                                                    <FiTwitter className='text-[13px] md:text-[18px] text-gray-900   hover:text-yellow-400' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full md:w-[50%] mx-auto  flex justify-center md:justify-end'>

                                <img src={mine} className='rotate-6 object-cover w-[90%] md:w-[75%] shadow-md drop-shadow-md shadow-gray-200 rounded-2xl border-2 border-violet-700 ' alt="Murugavel" />

                            </div>
                        </div>
                    </div>
                </section>



                {/* ------- my best services ---------- */}


                <section className='bg-gray-950  pb-10 md:pb-14 pt-10 md:pt-16' id='services'>
                    <div className='w-full px-4 md:w-[80%] mx-auto font-[poppins]'>
                        <div className='flex flex-col gap-y-2 justify-center items-center'>
                            <h3 className='font-bold text-[18px] md:text-[38px] uppercase text-white'>My Quality <span className='text-yellow-400'>Services</span> </h3>
                            <p className='font-semibold text-center text-[12px] md:text-[14px] text-gray-100'>
                                We put your ideas and thus your wishes in the form of a unique web project that inspires you and your customers.
                            </p>
                        </div>

                        <div className='mt-12 md:mt-20 flex justify-center items-center'>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-60'>
                                <div className='flex flex-col justify-center items-center gap-y-2 border border-dashed border-white rounded-lg px-2 md:px-16 py-2 md:py-5'>
                                    <CgWebsite className='text-white text-[40px] md:text-[60px] my-3' />
                                    <h2 className=' font-bold text-[14px] md:text-[16px] uppercase text-sky-500'>Web Development</h2>
                                    <p className='text-gray-100 text-[12px] md:text-[14px] text-center'>I break down complex user experience problems to create integrity-focused solutions that connect billions of people.</p>
                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-2 border border-dashed border-white rounded-lg px-2 md:px-16 py-2 md:py-5'>
                                    <MdDesignServices className='text-white text-[40px] md:text-[60px] my-3' />
                                    <h2 className=' font-bold text-[14px] md:text-[16px] uppercase text-sky-500'>UI Design</h2>
                                    <p className='text-gray-100 text-[12px] md:text-[14px] text-center'>I break down complex user experience problems to create integrity-focused solutions that connect billions of people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ---------- my works ------- */}

                <section className='bg-white pt-10 md:pt-14 pb-10 md:pb-14' id='works'>
                    <div className='flex flex-col justify-center '>
                        <div>
                            <h2 className='text-center font-bold text-[18px] md:text-[38px] uppercase text-gray-900'>My Recent Works</h2>
                        </div>
                        <div className='mt-2 md:mt-10'>
                            <Tabs />
                        </div>
                    </div>
                </section>

                {/* ------------------ My skills ----------- */}

                <section className='bg-gradient-to-b from-black to-gray-900  pb-10 md:pb-14 pt-10 md:pt-14' id='skills'>
                    <div className='w-full px-4 md:w-[80%] mx-auto font-[poppins] flex justify-center items-center '>

                        <div className=''>

                            <div>
                                <h3 className='font-bold text-[18px] md:text-[40px] uppercase text-center text-white mb-8 md:mb-14'>My Skills</h3>
                            </div>

                            <div className='grid grid-cols-2 md:grid-cols-5 gap-y-10 md:gap-y-24 gap-x-12 md:gap-x-28 w-full'>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>
                                    <IoLogoHtml5 className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px]  text-white'>HTML</h3>
                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>
                                    <IoLogoCss3 className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>CSS</h3>
                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>

                                    <TbBrandJavascript className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>JS</h3>
                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>

                                    <FaNodeJs className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>Node JS</h3>

                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>

                                    <SiMongodb className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>Mongo DB</h3>

                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>

                                    <GrMysql className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>MYSQL</h3>

                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>

                                    <GrReactjs className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>React JS</h3>

                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>

                                    <SiTailwindcss className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>Tailwindcss</h3>

                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>

                                    <SiFigma className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>Figma</h3>

                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>

                                    <FaGitAlt className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>Git</h3>

                                </div>

                                <div className='flex flex-col justify-center items-center gap-y-3 border border-dashed border-white px-8 py-4 rounded-xl'>

                                    <CiServer className='text-white text-[32px] md:text-[40px]' />

                                    <h3 className='font-bold text-[12px] md:text-[14px] text-white'>Server</h3>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>


                {/* ----------------- contact --------------- */}

                <section className=' font-[poppins] ' id='contact'>
                    <div className='w-full mx-auto'>

                        <div class=" pt-8 flex flex-col gap-y-8">
                            <h2 class=" text-center text-[22px] md:text-2xl font-bold text-gray-800 md:mb-6 lg:text-[30px] uppercase ">Get in touch</h2>
                        </div>

                        <div className='flex  flex-col md:flex-row items-center gap-y-5 w-full md:w-[80%] mx-auto'>

                            <div className='w-full md:w-[50%] mx-auto px-4 md:px-0' >

                                <p class="mx-auto font-[roboto] w-full md:w-[80%] text-center text-gray-20 text-[12px]  md:text-[14px]"> As a passionate MERN stack developer, I'm always eager to connect and collaborate on innovative projects. Whether you have a question, an exciting opportunity, or just want to say hello, feel free to reach out! </p>

                                {/* <Contact /> */}

                                <div className='mt-4 md:mt-7 flex justify-center items-center'>
                                    <a href="https://api.whatsapp.com/send?phone=6379172058" target="_blank" rel="noopener noreferrer" className="">

                                        <button className='bg-gray-900 text-white shadow-md drop-shadow-md h-8 md:h-10 pl-3 rounded-md flex items-center gap-x-3  font-[poppins] text-[14px] md:text-[16px] hover:scale-105 duration-500'> Contact Me! <div className='px-3 bg-green-600  rounded-r-md'><RiWhatsappLine className='text-white h-8 md:h-10    text-[22px] font-bold' /> </div>  </button>
                                    </a>
                                </div>

                            </div>

                            <div className='w-full md:w-[50%] mx-auto'>
                                <div className='flex justify-center md:justify-center '>
                                    <img src={contact} className='w-full md:w-[80%] object-cover ' alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </section>



            </div>
        </>
    );
}

export default Home;
