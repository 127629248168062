import React, { useState } from 'react';

import landing from '../images/landing_page.png'
import main from '../images/main_page.png'
import vfx from '../images/vfx.png'
import direction from '../images/direction.png'
import ecommerce from '../images/ecommerce.png'
import prime from '../images/PrimeThink.png'
import kenmai from '../images/Kenmai.png'

const Tabs = () => {
    const [activeTab, setActiveTab] = useState('all'); // State to manage active tab

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="w-full px-4 md:w-[80%] mx-auto mt-4 md:mt-12 font-[poppins]">
            {/* Tab Buttons */}
            <div className="flex justify-around bg-gray-100 p-2 md:p-4 rounded-t-lg  text-[12px] md:text-[14px]">


                <button
                    className={`px-4 py-2 cursor-pointer ${activeTab === 'all' ? 'bg-gray-300 rounded' : ''}`}
                    onClick={() => handleTabClick('all')}
                >
                    All
                </button>
                <button
                    className={`px-4 py-2 cursor-pointer ${activeTab === ' Web Pages' ? 'bg-gray-300 rounded' : ''}`}
                    onClick={() => handleTabClick('web')}
                >
                    Web Pages
                </button>
                <button
                    className={`px-4 py-2 cursor-pointer ${activeTab === 'courses' ? 'bg-gray-300 rounded' : ''}`}
                    onClick={() => handleTabClick('courses')}
                >
                    Landing Pages
                </button>
                <button
                    className={`px-4 py-2 cursor-pointer ${activeTab === 'ui' ? 'bg-gray-300 rounded' : ''}`}
                    onClick={() => handleTabClick('ui')}
                >
                    UX / UI
                </button>
            </div>

            {/* Tab Panels */}
            <div className="bg-gray-50 md:p-4 rounded-b-lg">

                {activeTab === 'all' && (
                    <>


                        <div className='flex justify-center py-10'>

                            <div className='grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-10'>

                                <div className=' h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={main} className='object-cover drop-shadow-md shadow-md' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={direction} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={landing} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={vfx} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={prime} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={ecommerce} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={kenmai} className='' alt="" />
                                    </div>
                                </div>


                            </div>

                        </div>



                    </>
                )}
                {activeTab === 'web' && (
                    <>
                        <div className='flex justify-center'>
                            <div className='grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-10'>

                                <div className=' h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm '>
                                    <div>
                                        <img src={main} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={direction} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={landing} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={vfx} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={prime} className='' alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                )}
                {activeTab === 'courses' && (
                    <>
                        <div>

                        </div>
                    </>
                )}
                {activeTab === 'ui' && (
                    <>
                        <div className='flex justify-center py-10'>

                            <div className='grid grid-cols-1 md:grid-cols-3 gap-x-8 gap-y-10'>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={prime} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={ecommerce} className='' alt="" />
                                    </div>
                                </div>

                                <div className='h-80 overflow-y-scroll shadow-md drop-shadow-md rounded-sm'>
                                    <div>
                                        <img src={kenmai} className='' alt="" />
                                    </div>
                                </div>


                            </div>

                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Tabs;
